// GLOWEB-5448 RUBY users limitation
(function() {
    'use strict';

    var rubyMsgBar = document.querySelector('#ru-message-bar'),
        msgBar = document.querySelector('.message-bar:not(#ru-message-bar)');

    if(rubyMsgBar) {
        $.ajax({
            url: 'https://' + avastGlobals.web.domain + '/' + avastGlobals.web.locale + '/geo-data'
        }).done(function(response) {
            var region = JSON.parse(response).region;

            if (region === 'RU' || region === 'BY') {
                if(msgBar) msgBar.style.display = 'none';
                rubyMsgBar.style.display = 'block';
            };
        });
    }

})()
